
import {defineComponent, ref, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {TypeResources} from "@/models";
import {DateTime} from 'luxon';
import Edit from "@/views/typeResources/Edit.vue";

export default defineComponent({
	emits: ["dialog-closed", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: 300
		},
		item: {
			type: Object as PropType<TypeResources.TypeResource>,
			default: null
		}
	},
	components: {
		Edit
	},
	setup(props) {
		const { t } = useI18n();
		const {commit} = useStore();

		const isReplace = () => {
			isDesktop.value
				? commit('globals/setDetailsTypeResource', props.open)
				: commit('globals/setDetailsTypeResource', false)
		}

		const isDesktop = ref(true);
		isDesktop.value = window.innerWidth >= 1264;
		window.addEventListener('resize', () => {
			isDesktop.value = window.innerWidth >= 1264;
			isReplace();
		});

		commit('globals/setDetailsTypeResource', false)
		watch(() => props.open, () => isReplace());

		const isEditOpened = ref(false);
		const edit = () => isEditOpened.value = true;

		return { t, DateTime, isEditOpened, edit }
	}
})
