
import {defineComponent, onMounted, ref, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRouter, useRoute} from 'vue-router';
import {Maps, Searches} from "@/models";
import {injectOrThrow} from "@/helpers/inject";
import uniqBy from 'lodash/uniqBy'
import axios from "axios";

export default defineComponent({
	setup() {
		const { t } = useI18n();
		const { state } = useStore();
		const router = useRouter();
		const route = useRoute();

		const searchText = ref("");

		const items = ref([] as Maps.Map[]);
		function select(item: Maps.Map) {
			router.push({name: 'linkedResources', params: {idTypeRes: item.id_type_res1, idRes: item.id_res_name1}});
			searchText.value = "";
			items.value = [];
		}

		const selectedType = ref({} as Searches.jsonKey)
		const types = ref([
			{id: "default", name: t("dictionary.default")},
			{id: "resource", name: t("dictionary.resource")}
		]);
		function selectType(selected: Searches.jsonKey) {
			selectedType.value = selected ?? {};

			if(selected.id === 'resource') {
				jsonKeys();
			} else {
				descriptions.value = [];
				selectedDescription.value = null;
			}
		}

		onMounted(() => {
			selectType(types.value[0]);
		})

		watch(() => route.name, () => {
			if(route.name === "map") {
				selectType(types.value[0]);
			}
		})

		const serviceSearch = injectOrThrow<Searches.SearchService>('Searches.SearchService');

		const jsonKeys = async () => {
			descriptions.value = await serviceSearch.jsonKeys(state.globals.enterpriseId, Number(route.params.idTypeRes));
		}

		const selectedDescription = ref({} as Searches.jsonKey|null)
		const descriptions = ref([] as Searches.jsonKey[]);
		function selectDescription(selected: Searches.jsonKey|null) {
			selectedDescription.value = selected ?? null;
		}

		const serviceMaps = injectOrThrow<Maps.MapService>('Maps.MapService');
		const loading = ref(false);

		const isCancelRequest = ref(false);

		const search = async (name: string = "") => {
			searchText.value = name;

			loading.value = true;

			if(name.length === 0) {
				items.value = [];
				loading.value = false;
				return;
			}

			const axiosSource = axios.CancelToken.source();

			if (isCancelRequest.value) axiosSource.cancel();
			isCancelRequest.value = true;

			let response = ref([] as Maps.Map[]);
			if(selectedType.value.id === "resource" && selectedDescription.value?.id) {
				response.value = await serviceSearch.resources(state.globals.enterpriseId, Number(route.params.idTypeRes), selectedDescription.value.id, name, axiosSource.token)
					.then(data => {
						isCancelRequest.value = false;
						return data;
					})
					.catch(err => {
						if (axios.isCancel(err)) {
							isCancelRequest.value = false;
							return [];
						}
					}) ?? []
			} else if(selectedType.value.id === "default") {
				response.value = await serviceMaps.search(state.globals.enterpriseId, name, axiosSource.token)
					.then(data => {
						isCancelRequest.value = false;
						return data;
					})
					.catch(err => {
						if (axios.isCancel(err)) {
							isCancelRequest.value = false;
							return [];
						}
					}) ?? []
			}

			items.value = uniqBy(response.value ?? [], "id_res_name1");
			loading.value = false;
		}

		return {
			t, route,
			searchText,
			items, select,
			types, selectedType, selectType,
			descriptions, selectedDescription, selectDescription,
			loading, search
		}
	}
});
