import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, Transition as _Transition } from "vue"
import _imports_0 from '@/assets/images/no-image.png'


const _hoisted_1 = { class: "py-2 px-4" }
const _hoisted_2 = { class: "row align-center" }
const _hoisted_3 = { class: "col py-0" }
const _hoisted_4 = { class: "subtitle-1" }
const _hoisted_5 = { class: "col-auto py-0" }
const _hoisted_6 = {
  key: 0,
  class: "px-4"
}
const _hoisted_7 = { class: "row mx-n2 mb-2 align-center" }
const _hoisted_8 = { class: "col-auto py-0 px-1" }
const _hoisted_9 = { class: "app-details-item-avatar" }
const _hoisted_10 = ["src"]
const _hoisted_11 = {
  key: 1,
  src: _imports_0
}
const _hoisted_12 = { class: "col py-0 px-1" }
const _hoisted_13 = { class: "text-body-2" }
const _hoisted_14 = { class: "col-auto py-0 px-1" }
const _hoisted_15 = {
  key: 0,
  class: "text-body-2"
}
const _hoisted_16 = { class: "text-body-2" }
const _hoisted_17 = {
  key: 1,
  class: "text-body-2"
}
const _hoisted_18 = { class: "text-body-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_tooltip = _resolveComponent("app-tooltip")!
  const _component_Edit = _resolveComponent("Edit")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "translate-right" }, {
      default: _withCtx(() => [
        (_ctx.open)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "app-details",
              style: _normalizeStyle(`width: ${_ctx.width}px`)
            }, [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t("button.details")), 1)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_app_button, {
                      icon: "",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit("dialog-closed")), ["stop"])),
                      class: "mr-n2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_app_icon, {
                          icon: "cross",
                          size: 14
                        })
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              (_ctx.item.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("div", _hoisted_9, [
                          ((_ctx.item.avatar ?? "").indexOf("base64") > 0)
                            ? (_openBlock(), _createElementBlock("img", {
                                key: 0,
                                src: _ctx.item.avatar
                              }, null, 8, _hoisted_10))
                            : (_openBlock(), _createElementBlock("img", _hoisted_11))
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.item.name), 1)
                      ]),
                      _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_app_tooltip, { placement: "bottom-end" }, {
                          content: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("button.edit")) + " " + _toDisplayString(_ctx.t("dictionary.typeResource")), 1)
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_app_button, {
                              block: "",
                              small: "",
                              icon: "",
                              class: "text-blue",
                              onClick: _ctx.edit,
                              style: {"padding-left":"6px","padding-right":"6px"}
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_app_icon, {
                                  icon: "edit",
                                  size: 16
                                })
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        })
                      ])
                    ]),
                    (_ctx.item.comment)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_15, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.t("dictionary.comment")) + ":", 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.item.comment), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("p", _hoisted_16, [
                      _createElementVNode("strong", null, _toDisplayString(_ctx.t("dictionary.dateTimeStart")) + ":", 1),
                      _createTextVNode(" " + _toDisplayString(_ctx.DateTime.fromISO(_ctx.item.date_time_start).toFormat("MMM dd, yyyy - HH:mm")), 1)
                    ]),
                    (_ctx.item.date_time_end)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.t("dictionary.dateTimeEnd")) + ":", 1),
                          _createTextVNode(" " + _toDisplayString(_ctx.DateTime.fromISO(_ctx.item.date_time_end).toFormat("MMM dd, yyyy - HH:mm")), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (Object.keys(_ctx.item.description_pattern).length > 0)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("p", _hoisted_18, [
                            _createElementVNode("strong", null, _toDisplayString(_ctx.t("dictionary.descriptionPattern")) + ":", 1)
                          ]),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(_ctx.item.description_pattern), (item, i) => {
                            return (_openBlock(), _createElementBlock("p", {
                              class: "text-body-2",
                              key: i
                            }, _toDisplayString(item[0]) + ": " + _toDisplayString(item[1]), 1))
                          }), 128))
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 4))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_Edit, {
      open: _ctx.isEditOpened,
      onDialogClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isEditOpened = false)),
      item: _ctx.item,
      onItemChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit("item-changed")))
    }, null, 8, ["open", "item"])
  ], 64))
}