export const time = (seconds) => {
	let time = Number(seconds);
	let d = Math.floor(time / (3600 * 24));
	let h = Math.floor(time % (3600 * 24) / 3600);
	let m = Math.floor(time % 3600 / 60);
	let s = Math.floor(time % 60);

	return { d, h, m, s }
}

export const timeName = (t) => {
	let { d, h, m, s } = time(t);

	let days = d > 0 ? d + `d ` : "";
	let hours = h > 0 ? h + `h ` : "";
	let minutes = m > 0 ? m + `m ` : "";
	let seconds = s > 0 ? s + `s ` : "";

	return days + hours + minutes + seconds;
}
