
import {defineComponent, ref, watch, PropType} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {Pairs, Resources} from "@/models";
import {DateTime} from 'luxon';
import {injectOrThrow} from "@/helpers/inject";
import {ucFirst} from '@/helpers/firstRegister';
import HistoryListing from "@/views/maps/components/details/HistoryListing.vue";
import Edit from "@/views/resources/Edit.vue";

export default defineComponent({
	emits: ["dialog-closed", "pair-updated", "item-changed"],
	props: {
		open: {
			type: Boolean,
			default: false
		},
		width: {
			type: Number,
			default: 300
		},
		item: {
			type: Object as PropType<Resources.Resource>,
			default: null
		}
	},
	components: {
		HistoryListing,
		Edit
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const {commit} = useStore();

		const service = injectOrThrow<Pairs.PairService>('Pairs.PairService');

		const isLoadingBlock = ref(false);
		const block = async (id_pair: number) => {
			isLoadingBlock.value = true;

			try {
				await service.block(id_pair);

				commit('notification/successMessage', {title: t("form.notify.success.blocked", { name: ucFirst(t("dictionary.pair")) })});
				emit("pair-updated");
			} catch (e) {
				return;
			} finally {
				isLoadingBlock.value = false;
			}
		};

		const isLoadingFixed = ref(false);
		const fixed = async (id_pair: number) => {
			isLoadingFixed.value = true;

			try {
				await service.fixed(id_pair);

				commit('notification/successMessage', {title: t("form.notify.success.fixed", { name: ucFirst(t("dictionary.pair")) })});
				emit("pair-updated");
			} catch (e) {
				return;
			} finally {
				isLoadingFixed.value = false;
			}
		};

		const unfixed = async (id_pair: number) => {
			isLoadingFixed.value = true;

			try {
				await service.unfixed(id_pair);

				commit('notification/successMessage', {title: t("form.notify.success.unfixed", { name: ucFirst(t("dictionary.pair")) })});
				emit("pair-updated");
			} catch (e) {
				return;
			} finally {
				isLoadingFixed.value = false;
			}
		};

		const isReplace = () => {
			isDesktop.value
				? commit('globals/setDetailsResource', props.open)
				: commit('globals/setDetailsResource', false)
		}

		const isDesktop = ref(true);
		isDesktop.value = window.innerWidth >= 1264;
		window.addEventListener('resize', () => {
			isDesktop.value = window.innerWidth >= 1264;
			isReplace();
		});

		commit('globals/setDetailsResource', false)
		watch(() => props.open, () => isReplace());

		const isEditOpened = ref(false);

		return {
			t, DateTime,
			block, isLoadingBlock,
			fixed, unfixed, isLoadingFixed,
			isEditOpened
		}
	}
})
