
import {defineComponent, ref, computed, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {injectOrThrow} from "@/helpers/inject";
import {Pairs, TypeResources, Resources, Labels, TypeLinks} from "@/models";
import {useForm, useField} from 'vee-validate';
import {ucFirst} from '@/helpers/firstRegister';
import {useRoute} from "vue-router";
import { constants } from '@/helpers/constants';

export default defineComponent({
	emits: ["dialog-closed", "item-added"],
	props: {
		open: {
			type: Boolean,
			default: false
		}
	},
	setup(props, {emit}) {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();

		const {handleSubmit, handleReset} = useForm();

		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		const resources = computed(() => store.state.dictionaries.resources.map(resource => {
			return {
				...resource,
				id_type_res_name: typeResources.value.find(typeResource => typeResource.id === resource.id_type_res)?.name
			}
		}) as Resources.Resource[]);

		const DictionaryQueryParams = {pageSize: constants.limitlessPageSize, pageNumber: 1};
		const serviceLabels = injectOrThrow<Labels.LabelService>('Labels.LabelService');

		const lbl1 = ref("");
		const lbl2 = ref("");

		const load = async (id_tag: number): Promise<Labels.LabelList> =>
			await serviceLabels.list(DictionaryQueryParams, id_tag);

		const { value: selectedResource1, errorMessage: resourceError1 } = useField<Resources.Resource>('selectedResource1', 'required');
		function selectResource1(selected: Resources.Resource) {
			selectedResource1.value = selected ?? {};
			const response = load(selectedResource1.value.id_tag);
			response.then(list => lbl1.value = list.items[0]?.label ?? "");
		}

		const { value: selectedResource2, errorMessage: resourceError2 } = useField<Resources.Resource>('selectedResource2', 'required');
		function selectResource2(selected: Resources.Resource) {
			selectedResource2.value = selected ?? {};
			const response = load(selectedResource2.value.id_tag);
			response.then(list => lbl2.value = list.items[0]?.label ?? "");
		}

		const typeLinks = computed(() => store.state.dictionaries.typeLinks as TypeLinks.TypeLink[]);
		const { value: selectedTypeLink, errorMessage: typeLinkError } = useField<TypeLinks.TypeLink>('selectedTypeLink', 'required');
		function selectTypeLink(selected: TypeLinks.TypeLink) {
			selectedTypeLink.value = selected ?? {};
		}

		const set = async () => {
			await store.dispatch('dictionaries/getResources');
			await store.dispatch('dictionaries/getTypeLinks');

			const findResource = resources.value.find(resource => resource.id === Number(route.query.id?.slice(-1) ?? route.params.idRes));
			if(findResource)
				selectResource1(findResource);
		}

		watch(() => props.open, () => {
			if(props.open)
				set()
		});

		const isLoading = ref(false);
		const service = injectOrThrow<Pairs.PairService>('Pairs.PairService');

		const add = handleSubmit(async () => {
			isLoading.value = true;

			try {
				await service.addOne({
					lbl1: lbl1.value,
					lbl2: lbl2.value,
					link: selectedTypeLink.value.id
				});

				store.commit('notification/successMessage', {title: t("form.notify.success.added", { name: ucFirst(t("dictionary.pair")) })});
				emit("item-added");
				closeModal();
			} catch (e) {
				return;
			} finally {
				isLoading.value = false;
			}
		});

		const closeModal = () => {
			emit('dialog-closed');
			handleReset();
		}

		return {
			t,
			resources,
			selectedResource1, resourceError1, selectResource1,
			selectedResource2, resourceError2, selectResource2,
			typeLinks, selectedTypeLink, typeLinkError, selectTypeLink,
			isLoading, add, closeModal
		}
	}
})
