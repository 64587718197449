
import {defineComponent, ref, computed, watch, onUnmounted} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRoute, useRouter} from "vue-router";
import Search from "./Search.vue";
import Add from "./Add.vue";
import AddOne from "./AddOne.vue";
import AddResource from "@/views/resources/Add.vue";
import AddTypeResource from "@/views/typeResources/Add.vue";
import DetailsTypeResource from "@/views/maps/components/details/TypeResource.vue";
import DetailsResource from "@/views/maps/components/details/Resource.vue";
import {ucFirst} from '@/helpers/firstRegister';

import {Resources, TypeResources, TypeLinks} from "@/models";

export default defineComponent({
	components: {
		Search,
		Add,
		AddOne,
		AddResource,
		AddTypeResource,
		DetailsTypeResource,
		DetailsResource
	},
	setup() {
		const { t } = useI18n();
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const resources = computed(() => store.state.dictionaries.resources as Resources.Resource[]);
		const typeResources = computed(() => store.state.dictionaries.typeResources as TypeResources.TypeResource[]);
		const nameTypeResource = computed(() => typeResources.value.find(typeResource => typeResource.id === Number(route.params.idTypeRes))?.name);

		const typeLinks = computed(() => store.state.dictionaries.typeLinks as TypeLinks.TypeLink[]);
		const selectedTypeLink = ref([] as TypeLinks.TypeLink[])
		function selectTypeLink(selected: TypeLinks.TypeLink[]) {
			selectedTypeLink.value = selected ?? [];
		}

		const nameMap = computed(() => resources.value.find(resource => resource.id === Number(route.params.idRes))?.name);
		const routeQueryIds = computed(() => {
			const ids = route.query.id
			return Object.assign([], ids ? typeof ids === 'object' ? ids : [ids] : []).map(id => Number(id))
		})
		const nameMapChildren = computed(() => {
			return routeQueryIds.value.map(id => {
				return {
					id,
					name: resources.value.find(resource => resource.id === Number(id))?.name
				}
			})
		});

		const prevTree = (i: number) => router.replace({ query: {id: routeQueryIds.value.slice(0, i + 1)} });

		const isAddOpened = ref(false);
		const isAddOneOpened = ref(false);
		const isAddResourceOpened = ref(false);
		const isAddTypeResourceOpened = ref(false);
		const isRefreshRequest = ref(false);
		const isSearchOpened = ref(false);
		const isFilterOpened = ref(false);

		const mapView = computed(() => store.state.globals.mapView);

		const title = computed(() => {
			return route.params.idTypeRes
				? route.params.idRes
					? nameMapChildren.value.length
						? nameMapChildren.value[nameMapChildren.value.length - 1].name
						: nameMap.value
					: nameTypeResource.value
				: t("title.typeResources");
		})

		const typeResource = computed(() => typeResources.value.find(typeResource => typeResource.id === Number(route.params.idTypeRes)))

		onUnmounted(() => {
			store.commit('globals/setDetailsTypeResource', false)
			store.commit('globals/setDetailsResource', false)
		});

		watch(() => route.params.idRes, async () => {
			closeDialog()

			if (route.params.idRes) return;
			isFilterOpened.value = false;
		});

		watch(() => route.params.idTypeRes, () => closeDialog())
		watch(() => route.query.id, () => closeDialog())

		const detailsId = ref();

		const itemDetailsTypeResource = ref({} as TypeResources.TypeResource);
		const isDetailTypeResourceOpened = computed(() => store.state.globals.detailsTypeResource);
		const openDetailsTypeResource = (id: number) => {
			detailsId.value = Number(id)
			itemDetailsTypeResource.value = typeResources.value.find(typeResource => typeResource.id === detailsId.value) ?? {} as TypeResources.TypeResource
			store.commit('globals/setDetailsTypeResource', true)
			store.commit('globals/setDetailsResource', false)
		}
		const closeDialogTypeResource = () => {
			store.commit("globals/setDetailsTypeResource", false)
			itemDetailsTypeResource.value = {} as TypeResources.TypeResource
			detailsId.value = null
		}

		const itemDetailsResource = ref({} as Resources.Resource);
		const isDetailResourceOpened = computed(() => store.state.globals.detailsResource);
		const openDetailsResource = async (id: number, object?: {id_pair: number, fixed: boolean}) => {
			detailsId.value = Number(id)
			itemDetailsResource.value = Object.assign(resources.value.find(resource => resource.id === detailsId.value), object) ?? {} as Resources.Resource
			store.commit('globals/setDetailsResource', true)
			store.commit('globals/setDetailsTypeResource', false)
		}
		const closeDialogResource = () => {
			store.commit("globals/setDetailsResource", false)
			itemDetailsResource.value = {} as Resources.Resource
			detailsId.value = null
		}

		const openDetails = (id: number, type: string, object?: {id_pair: number, fixed: boolean}) => {
			switch (type) {
				case 'typeResource':
					openDetailsTypeResource(id)
					break;
				case 'resource':
					openDetailsResource(id, object)
					break;
				default:
					break;
			}
		}

		const refreshLoad = () => {
			closeDialogResource();
			isRefreshRequest.value = true
		}

		const closeDialog = () => {
			closeDialogTypeResource();
			closeDialogResource();
		}

		return {
			t, store, route, ucFirst,
			nameTypeResource, nameMap, nameMapChildren,
			isAddOpened, isAddOneOpened,
			isRefreshRequest, prevTree, mapView, title,
			typeLinks, selectedTypeLink, selectTypeLink,
			isSearchOpened, isFilterOpened,
			isAddResourceOpened, typeResource, isAddTypeResourceOpened,
			detailsId, itemDetailsTypeResource, isDetailTypeResourceOpened, openDetailsTypeResource, closeDialogTypeResource,
			itemDetailsResource, isDetailResourceOpened, openDetailsResource, closeDialogResource,
			openDetails, refreshLoad, routeQueryIds
		}
	}
})
