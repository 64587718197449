import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "row mx-n1" }
const _hoisted_3 = { class: "col py-0 px-1" }
const _hoisted_4 = { class: "app-label" }
const _hoisted_5 = {
  key: 0,
  class: "col-3 py-0 px-1"
}
const _hoisted_6 = { class: "app-label" }
const _hoisted_7 = {
  key: 1,
  class: "col-3 py-0 px-1"
}
const _hoisted_8 = { class: "app-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_spinner = _resolveComponent("app-spinner")!
  const _component_app_autocomplete = _resolveComponent("app-autocomplete")!
  const _component_app_select = _resolveComponent("app-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.t("dictionary.search")), 1),
        _createVNode(_component_app_autocomplete, {
          items: _ctx.items,
          onItemSelected: _ctx.select,
          initSearchText: _ctx.searchText,
          onInput: _ctx.search,
          isRequest: "",
          iconPrepend: "",
          itemName: "name1",
          iconAppend: "",
          clearable: "",
          onSelectionCleared: _ctx.search
        }, _createSlots({ _: 2 }, [
          (_ctx.loading)
            ? {
                name: "iconAppend",
                fn: _withCtx(() => [
                  _createVNode(_component_app_spinner, {
                    loading: _ctx.loading,
                    small: ""
                  }, null, 8, ["loading"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["items", "onItemSelected", "initSearchText", "onInput", "onSelectionCleared"])
      ]),
      (_ctx.selectedType.id === "resource")
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t("dictionary.typeResource")), 1),
            _createVNode(_component_app_select, {
              items: _ctx.descriptions,
              selected: _ctx.selectedDescription,
              onSelectItem: _ctx.selectDescription
            }, null, 8, ["items", "selected", "onSelectItem"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.route.name !== "map")
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("dictionary.searchBy")), 1),
            _createVNode(_component_app_select, {
              items: _ctx.types,
              selected: _ctx.selectedType,
              onSelectItem: _ctx.selectType
            }, null, 8, ["items", "selected", "onSelectItem"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}