import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col py-0" }
const _hoisted_3 = { class: "subtitle-1" }
const _hoisted_4 = { class: "col-auto py-0" }
const _hoisted_5 = { class: "mb-6 relative" }
const _hoisted_6 = { class: "app-label" }
const _hoisted_7 = {
  key: 0,
  class: "app-input__details absolute"
}
const _hoisted_8 = { class: "error--text" }
const _hoisted_9 = { class: "mb-6 relative" }
const _hoisted_10 = { class: "app-label" }
const _hoisted_11 = {
  key: 0,
  class: "app-input__details absolute"
}
const _hoisted_12 = { class: "error--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_autocomplete = _resolveComponent("app-autocomplete")!
  const _component_app_dialog = _resolveComponent("app-dialog")!

  return (_openBlock(), _createBlock(_component_app_dialog, {
    open: _ctx.open,
    width: 450,
    onDialogClosed: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t("button.add")) + " " + _toDisplayString(_ctx.t("dictionary.pair")) + " " + _toDisplayString(_ctx.t("dictionary.default")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_app_button, {
            icon: "",
            onClick: _ctx.closeModal,
            class: "ma-n3"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_icon, {
                icon: "cross",
                size: 16
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      _createElementVNode("form", {
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.add && _ctx.add(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.t("dictionary.left")) + " " + _toDisplayString(_ctx.t("dictionary.resource")), 1),
          _createVNode(_component_app_autocomplete, {
            items: _ctx.resources,
            selected: _ctx.selectedResource1,
            onItemSelected: _ctx.selectResource1,
            isSubGroup: "",
            isSubGroupName: "id_type_res_name"
          }, null, 8, ["items", "selected", "onItemSelected"]),
          (_ctx.resourceError1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.resourceError1), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.t("dictionary.right")) + " " + _toDisplayString(_ctx.t("dictionary.resource")), 1),
          _createVNode(_component_app_autocomplete, {
            items: _ctx.resources,
            selected: _ctx.selectedResource2,
            onItemSelected: _ctx.selectResource2,
            isSubGroup: "",
            isSubGroupName: "id_type_res_name"
          }, null, 8, ["items", "selected", "onItemSelected"]),
          (_ctx.resourceError2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.resourceError2), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_app_button, {
          block: "",
          large: "",
          color: "bg-blue text-white",
          onClick: _ctx.add,
          loading: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("button.add")), 1)
          ]),
          _: 1
        }, 8, ["onClick", "loading"])
      ], 32)
    ]),
    _: 1
  }, 8, ["open", "onDialogClosed"]))
}