
import {defineComponent, ref, computed, watch, onMounted} from 'vue';
import {useI18n} from 'vue-i18n';
import {useStore} from 'vuex';
import {useRouter} from "vue-router";
import {DateTime} from 'luxon';
import {timeName} from '@/helpers/DateAndTime';
import {ucFirst} from '@/helpers/firstRegister';
import {TypeLinks} from "@/models";

export default defineComponent({
	props: {
		open: {
			type: Boolean,
			default: false
		},
		id_res: {
			type: Number,
			required: true
		}
	},
	setup(props) {
		const { t } = useI18n();
		const { state, dispatch } = useStore();
		const router = useRouter();

		const isFilterOpened = ref(false);

		const typeLinks = computed(() => state.dictionaries.typeLinks as TypeLinks.TypeLink[]);
		const selectedTypeLink = ref([] as TypeLinks.TypeLink[])
		function selectTypeLink(selected: TypeLinks.TypeLink[]) {
			selectedTypeLink.value = selected ?? [];
			load()
		}

		const loading = ref(true);
		const load = async () => {
			if(state.globals.enterpriseId) {
				loading.value = true;
				await dispatch('dictionaries/getHistory', {
					id_res: props.id_res,
					lst_of_links: selectedTypeLink.value
				});
				loading.value = false;
			} else {
				router.push({name: "change_enterprise"})
			}
		}

		onMounted(async () => {
			await load();
			await dispatch('dictionaries/getTypeLinks');
		});

		watch(() => props.id_res, () => load());

		const items = computed(() => state.dictionaries.histories.map(item => {
			return {
				...item,
				date_time_start: DateTime.fromISO(item.date_time_start).toFormat("MMM dd, yyyy - HH:mm"),
				date_time_end: item.date_time_end ? DateTime.fromISO(item.date_time_end).toFormat("MMM dd, yyyy - HH:mm") : null,
				duration: timeName(DateTime.fromISO(item.date_time_end ?? DateTime.now()).diff(DateTime.fromISO(item.date_time_start), ["seconds"]).toObject().seconds)
			}
		}));

		return {
			t, loading, items, ucFirst,
			isFilterOpened, typeLinks, selectedTypeLink, selectTypeLink
		}
	}
})
